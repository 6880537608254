import React, { useContext } from "react";
import { FiMapPin } from "react-icons/fi";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";

const Map = () => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full mt-[60px]">
        {
          rpdata?.labels?.general?.titleMap ?
            <h2 className="text-center pb-5">
              {rpdata?.labels?.general?.titleMap}
            </h2>
            :
            <h2 className="text-center pb-5">
              We Cover {rpdata?.dbPrincipal?.miles} Miles Around {rpdata?.dbPrincipal?.location?.[0].address}
            </h2>
        }
        {
          rpdata?.dbPrincipal?.location.length > 0 ?
            <ul className="pt-4 pb-4 md:p-0 flex flex-col md:flex-row justify-between md:justify-center items-center ">
              {rpdata?.dbPrincipal?.location?.map((item, index) => {
                return (
                  <li className="py-2 px-3 w-full md:w-4/5 flex flex-col items-center" key={index} >
                    <div className="w-full">
                      <Link to="/contact"  className="flex items-center justify-center text-[30px] font-bold">
                        <FiMapPin fontSize={25} />
                        <span className="pl-2">{item.address}</span>
                      </Link>
                      <iframe src={item.url} className="w-full h-[500px]" />
                    </div>
                  </li>
                );
              })}
            </ul>
            : null
        }
      </div>
    </div>
  )
}

export default Map